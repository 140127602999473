@tailwind base;
@tailwind components;

html {
  @apply h-full;
}

body {
  @apply h-full;
  background-color: rgb(245, 245, 245);
}

div#app {
  @apply h-full;
}

@tailwind utilities;
